import PracticeModal from 'pages/Report/__partials/Practice/PracticeModal';
import { getPracticeAction, removePracticeAction } from 'store/actions/report.actions';
import { getPractice } from 'store/selectors/report.selectors';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ActionsIcon from 'UI/Icons/ActionsIcon';
import { useParams } from 'react-router-dom';
import { Col, Dropdown, Row } from 'antd';
import { Text } from 'UI/Typography';
import styles from './styles.module.css';
import Modal from '../../../../UI/Modal';
import Pagination from '../../../../UI/Pagination';

const { Confirm } = Modal;

const Practice = ({
  managePracticeVisible,
  toggleManagePractice,
  activeReportId,
}: {
  managePracticeVisible: boolean;
  toggleManagePractice: (arg1: boolean) => void;
  activeReportId: number;
}) => {
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [selectedPracticeId, setSelectedPracticeId] = useState<number | null>(null);
  const [editablePracticeId, setEditablePracticeId] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const { studentId } = useParams();
  const practiceList = useSelector(getPractice);

  const editablePractice = useMemo(() => {
    return practiceList?.internships?.find((value) => value.id === editablePracticeId) || null;
  }, [editablePracticeId, practiceList?.internships]);

  const handleDeletePractice = useCallback(() => {
    dispatch(
      removePracticeAction({
        id: Number(selectedPracticeId),
        studentId: Number(studentId),
        izelId: activeReportId,
      }),
    );
    setDeleteVisible(false);
  }, [activeReportId, dispatch, selectedPracticeId, studentId]);

  const handlePageChange = useCallback(
    (value: number) => {
      setCurrentPage(value);
      if (activeReportId) {
        dispatch(
          getPracticeAction({
            id: Number(studentId),
            izelId: activeReportId,
            page: value - 1,
            size: 10,
          }),
        );
      }
    },
    [activeReportId, dispatch, studentId],
  );

  const dropdownItems = useCallback(
    (practiceId: number) => {
      return {
        items: [
          {
            key: '1',
            label: (
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  setEditablePracticeId(practiceId);
                  toggleManagePractice(true);
                }}
              >
                Ändern
              </span>
            ),
          },
          {
            key: '2',
            label: (
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedPracticeId(practiceId);
                  setDeleteVisible(true);
                }}
              >
                Löschen
              </span>
            ),
          },
        ],
      };
    },
    [toggleManagePractice],
  );

  const handleChangeEditablePracticeId = useCallback((value: null | number) => {
    setEditablePracticeId(value);
  }, []);

  useEffect(() => {
    if (activeReportId) {
      dispatch(
        getPracticeAction({ id: Number(studentId), izelId: activeReportId, page: 0, size: 10 }),
      );
    }
  }, [activeReportId, dispatch, studentId]);

  return (
    <div>
      {practiceList?.internships?.map((practice) => {
        return (
          <div key={practice.id} className={styles.practiceWrapper}>
            <Row gutter={8} className={styles.practiceItem} justify='space-between' align='middle'>
              <Col>
                <Row gutter={8}>
                  <Col>
                    <Text level={1}>Praktikumszeitraum</Text>
                  </Col>
                  <Col>
                    <Text level={2} bold>
                      {practice.startDate} - {practice.endDate}
                    </Text>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Dropdown
                  trigger={['click']}
                  placement='bottomRight'
                  menu={dropdownItems(practice.id)}
                >
                  <ActionsIcon />
                </Dropdown>
              </Col>
            </Row>
            <Row gutter={8} className={styles.practiceItem}>
              <Col>
                <Text level={1}>Praktikumsbetrieb</Text>
              </Col>
              <Col>
                <Text level={2} bold>
                  {practice.organization}
                </Text>
              </Col>
            </Row>
            <Row gutter={8} className={styles.practiceItem}>
              <Col>
                <Text level={1}>Ort</Text>
              </Col>
              <Col>
                <Text level={2} bold>
                  {practice.city}
                </Text>
              </Col>
            </Row>
            <Row gutter={8} className={styles.practiceItem}>
              <Col>
                <Text level={1}>
                  Berufsfeld / <br />
                  Tätigkeitsbereich
                </Text>
              </Col>
              <Col>
                <Text level={2}>{practice.description}</Text>
              </Col>
            </Row>
          </div>
        );
      })}
      <div className={styles.paginationWrapper}>
        <Pagination
          pageSize={10}
          current={currentPage}
          onChange={(page: number) => handlePageChange(page)}
          total={practiceList?.count}
        />
      </div>
      <PracticeModal
        currentPage={currentPage}
        activeReportId={activeReportId}
        handleChangeEditablePracticeId={handleChangeEditablePracticeId}
        editablePractice={editablePractice}
        managePracticeVisible={managePracticeVisible}
        toggleManagePractice={toggleManagePractice}
      />
      <Confirm
        closable
        okText='Löschen'
        cancelText='Abbrechen'
        centered
        message='Sind sie sicher, dass Sie das Praktikum löschen wollen? Falls ja, wird der Eintrag permanent aus der Datenbank gelöscht und kann nicht wiederhergestellt werden.'
        open={deleteVisible}
        onOk={handleDeletePractice}
        onCancel={(event) => {
          event.stopPropagation();
          setDeleteVisible(false);
          setSelectedPracticeId(null);
        }}
      />
    </div>
  );
};

export default Practice;
