import {
  createSubjectAction,
  editSubjectAction,
  subjectTypes,
} from 'store/actions/subjects.actions';
import { getUserRole } from 'store/selectors/users.selectors';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { RadioChangeEvent } from 'antd';
import RadioGroup from 'UI/RadioGroup';
import { Text } from 'UI/Typography';
import Checkbox from 'UI/Checkbox';
import Modal from 'UI/Modal';
import Input from 'UI/Input';
import styles from './styles.module.css';

type AddItemModalType = {
  addItemModalVisible: boolean;
  toggleAddItemModal: (arg1: boolean) => void;
  page: number;
  selectedSubject?: subjectTypes;
  toggleEditSubject: (arg1: number | null) => void;
  active: boolean;
};

const AddSubjectModal = ({
  addItemModalVisible,
  toggleAddItemModal,
  page,
  toggleEditSubject,
  selectedSubject,
  active,
}: AddItemModalType) => {
  const [subjectName, setSubjectName] = useState('');
  const [subjectDescription, setSubjectDescription] = useState('');
  const [subjectState, setSubjectState] = useState(2);
  const [textBefore, setTextBefore] = useState(false);
  const [errorForUniqueName, setErrorForUniqueName] = useState(false);
  const userRole = useSelector(getUserRole);
  const dispatch = useDispatch();

  const editMode = useMemo(() => !!selectedSubject, [selectedSubject]);

  const handleSubjectName = useCallback((value: string) => {
    setErrorForUniqueName(false);
    setSubjectName(value);
  }, []);

  const handleSubjectDescription = useCallback((value: string) => {
    setSubjectDescription(value);
  }, []);

  const handleSubjectOptional = useCallback((e: RadioChangeEvent) => {
    setSubjectState(e.target.value);
  }, []);

  const handleChangeTextBefore = useCallback((value: boolean) => {
    setTextBefore(value);
  }, []);

  const handleResetModalState = useCallback(() => {
    setSubjectName('');
    setSubjectDescription('');
    setSubjectState(2);
    setTextBefore(false);
    setErrorForUniqueName(false);
    toggleAddItemModal(false);
    toggleEditSubject(null);
  }, [toggleAddItemModal, toggleEditSubject]);

  const handleCreateSubject = useCallback(() => {
    dispatch(
      createSubjectAction({
        name: subjectName,
        description: subjectDescription,
        page: page - 1,
        displayTextBeforeContent: textBefore,
        optional: subjectState === 2,
        active,
        onSuccess: () => {
          handleResetModalState();
        },
        onFailure: (message) => {
          if (message === 'not_unique') {
            setErrorForUniqueName(true);
          }
        },
      }),
    );
  }, [
    dispatch,
    handleResetModalState,
    page,
    subjectDescription,
    subjectName,
    subjectState,
    textBefore,
  ]);

  const handleEditSubject = useCallback(() => {
    dispatch(
      editSubjectAction({
        id: Number(selectedSubject?.id),
        name: subjectName,
        description: subjectDescription,
        page: page - 1,
        displayTextBeforeContent: textBefore,
        optional: subjectState === 2,
        active,
        onSuccess: () => {
          handleResetModalState();
        },
        onFailure: (message) => {
          if (message === 'not_unique') {
            setErrorForUniqueName(true);
          }
        },
      }),
    );
  }, [
    dispatch,
    handleResetModalState,
    page,
    selectedSubject?.id,
    subjectDescription,
    subjectName,
    subjectState,
    textBefore,
  ]);

  const handleManageSubject = useCallback(() => {
    if (editMode) {
      handleEditSubject();
    } else {
      handleCreateSubject();
    }
  }, [editMode, handleCreateSubject, handleEditSubject]);

  useEffect(() => {
    if (selectedSubject) {
      setSubjectName(selectedSubject.name);
      setSubjectDescription(selectedSubject.description || '');
      setSubjectState(selectedSubject.optional ? 2 : 1);
      setTextBefore(Boolean(selectedSubject?.displayTextAfterContent));
    }
  }, [selectedSubject]);

  return (
    <Modal
      centered
      title={editMode ? 'Schulfach' : 'Neues Schulfach'}
      open={addItemModalVisible}
      onOk={() => handleManageSubject()}
      onCancel={() => handleResetModalState()}
      closable
      okText={editMode ? 'Speichern' : 'Hinzufügen'}
      cancelText='Abbrechen'
      okDisabled={!subjectName}
      confirmLoading={false}
    >
      <div className={styles.subjectLabel}>
        <Text level={1} bold>
          Schulfach
        </Text>
      </div>
      <Input
        error={errorForUniqueName}
        value={subjectName}
        placeholder='bitte auswählen'
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleSubjectName(e.target.value)}
        maxLength={150}
        showCount
      />
      {errorForUniqueName && <div className={styles.errorText}>Name ist bereits vorhanden</div>}
      <div className={styles.descriptionLabel}>
        <Text level={1} bold>
          Beschreibung (Optional)
        </Text>
      </div>
      <Input.TextArea
        value={subjectDescription}
        placeholder='bitte auswählen'
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => handleSubjectDescription(e.target.value)}
        maxLength={1000}
        showCount
      />
      {userRole === 'ASSOCIATION_ADMIN' && (
        <>
          <div className={styles.typeLabel}>
            <Text level={1} bold>
              Typ
            </Text>
          </div>
          <RadioGroup
            value={subjectState}
            onChange={(e: RadioChangeEvent) => handleSubjectOptional(e)}
            groupOptions={[
              { key: 1, label: 'Bindend' },
              { key: 2, label: 'Optional' },
            ]}
          />
        </>
      )}
      {userRole === 'SCHOOL_ADMIN' && (
        <>
          <div className={styles.typeLabel}>
            <Text level={1} bold>
              Textplatzierung
            </Text>
          </div>
          <Checkbox
            checked={textBefore}
            onChange={(e: CheckboxChangeEvent) => handleChangeTextBefore(e.target.checked)}
          >
            <Text level={1} bold>
              Der Text wird im Anschluss angezeigt.
            </Text>
          </Checkbox>
        </>
      )}
    </Modal>
  );
};

export default AddSubjectModal;
