export const ActionNames = {
  LOGIN: 'LOGIN',
  SAVE_AUTH: 'SAVE_AUTH',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  FIRST_LOGIN: 'FIRST_LOGIN',
  RESET_PASSWORD: 'RESET_PASSWORD',
  CHANGE_USER_ROLE: 'CHANGE_USER_ROLE',
  // Subjects Action Names
  GET_SUBJECTS: 'GET_SUBJECTS',
  GET_SUBJECTS_SUCCESS: 'GET_SUBJECTS_SUCCESS',
  GET_SUBJECTS_TO_REORDER: 'GET_SUBJECTS_TO_REORDER',
  GET_REORDER_LIST_SUCCESS: 'GET_REORDER_LIST_SUCCESS',
  CREATE_SUBJECT: 'CREATE_SUBJECT',
  EDIT_SUBJECT: 'EDIT_SUBJECT',
  REORDER_SUBJECTS: 'REORDER_SUBJECTS',
  DEACTIVATE_SUBJECT: 'DEACTIVATE_SUBJECT',
  REACTIVATE_SUBJECT: 'REACTIVATE_SUBJECT',
  DELETE_SUBJECT: 'DELETE_SUBJECT',
  CLEAR_SUBJECT_LIST: 'CLEAR_SUBJECT_LIST',
  CREATE_SUBJECT_SUCCESS: 'CREATE_SUBJECT_SUCCESS',
  CREATE_SUBJECT_FAILURE: 'CREATE_SUBJECT_FAILURE',
  // item Action Names
  GET_ASSESSMENT_SCALES: 'GET_ASSESSMENT_SCALES',
  GET_ASSESSMENT_SCALES_SUCCESS: 'GET_ASSESSMENT_SCALES_SUCCESS',
  GET_ITEMS: 'GET_ITEMS',
  GET_ITEMS_SUCCESS: 'GET_ITEMS_SUCCESS',
  CREATE_ITEM: 'CREATE_ITEM',
  CREATE_ITEM_SUCCESS: 'CREATE_ITEM_SUCCESS',
  EDIT_ITEM: 'EDIT_ITEM',
  EDIT_ITEM_SUCCESS: 'EDIT_ITEM_SUCCESS',
  CREATE_SUB_ITEM: 'CREATE_SUB_ITEM',
  CREATE_SUB_ITEM_SUCCESS: 'CREATE_SUB_ITEM_SUCCESS',
  EDIT_SUB_ITEM: 'EDIT_SUB_ITEM',
  EDIT_SUB_ITEM_SUCCESS: 'EDIT_SUB_ITEM_SUCCESS',
  REMOVE_ITEM: 'REMOVE_ITEM',
  DEACTIVATE_ITEM: 'DEACTIVATE_ITEM',
  REACTIVATE_ITEM: 'REACTIVATE_ITEM',
  REMOVE_ITEM_SUCCESS: 'REMOVE_ITEM_SUCCESS',
  REMOVE_SUB_ITEM_SUCCESS: 'REMOVE_SUB_ITEM_SUCCESS',
  REORDER_SUB_ITEMS: 'REORDER_SUB_ITEMS',
  // group action names
  GET_GROUPS: 'GET_GROUPS',
  GET_ALL_GROUPS: 'GET_ALL_GROUPS',
  GET_ALL_GROUPS_SUCCESS: 'GET_ALL_GROUPS_SUCCESS',
  GET_GROUPS_SUCCESS: 'GET_GROUPS_SUCCESS',
  GET_GROUP_DETAILS: 'GET_GROUP_DETAILS',
  GET_GROUP_DETAILS_SUCCESS: 'GET_GROUP_DETAILS_SUCCESS',
  CREATE_GROUP: 'CREATE_GROUP',
  CREATE_GROUP_SUCCESS: 'CREATE_GROUP_SUCCESS',
  EDIT_GROUP: 'EDIT_GROUP',
  GET_GROUPS_STUDENTS: 'GET_GROUPS_STUDENTS',
  REMOVE_GROUP: 'REMOVE_GROUP',
  GET_GROUPS_STUDENTS_SUCCESS: 'GET_GROUPS_STUDENTS_SUCCESS',
  GET_GROUPS_TEACHERS: 'GET_GROUPS_TEACHERS',
  GET_GROUPS_TEACHERS_SUCCESS: 'GET_GROUPS_TEACHERS_SUCCESS',
  GET_GROUPS_SUBJECTS: 'GET_GROUPS_SUBJECTS',
  GET_GROUPS_SUBJECTS_SUCCESS: 'GET_GROUPS_SUBJECTS_SUCCESS',
  GET_SUBJECTS_TO_ADD: 'GET_SUBJECTS_TO_ADD',
  GET_SUBJECTS_TO_ADD_SUCCESS: 'GET_SUBJECTS_TO_ADD_SUCCESS',
  ADD_SUBJECTS_TO_GROUP: 'ADD_SUBJECTS_TO_GROUP',
  REMOVE_SUBJECT_FROM_GROUP: 'REMOVE_SUBJECT_FROM_GROUP',
  REMOVE_TEACHER_FROM_GROUP: 'REMOVE_TEACHER_FROM_GROUP',
  ADD_SUBJECTS_TO_GROUP_SUCCESS: 'ADD_SUBJECTS_TO_GROUP_SUCCESS',
  GET_TEACHERS_TO_ADD: 'GET_TEACHERS_TO_ADD',
  GET_TEACHERS_TO_ADD_SUCCESS: 'GET_TEACHERS_TO_ADD_SUCCESS',
  ADD_TEACHER_TO_GROUP: 'ADD_TEACHER_TO_GROUP',
  GET_STUDENTS_TO_ADD: 'GET_STUDENTS_TO_ADD',
  GET_STUDENTS_TO_ADD_SUCCESS: 'GET_STUDENTS_TO_ADD_SUCCESS',
  ADD_STUDENT_TO_GROUP: 'ADD_STUDENT_TO_GROUP',
  REMOVE_STUDENT_FROM_GROUP: 'REMOVE_STUDENT_FROM_GROUP',
  CREATE_GROUP_SUBJECT_SUMMARY: 'CREATE_GROUP_SUBJECT_SUMMARY',
  GET_GROUP_ALL_SUBJECTS: 'GET_GROUP_ALL_SUBJECTS',
  GET_GROUP_ALL_SUBJECTS_SUCCESS: 'GET_GROUP_ALL_SUBJECTS_SUCCESS',
  GET_GROUP_ITEMS: 'GET_GROUP_ITEMS',
  GET_GROUP_ITEMS_SUCCESS: 'GET_GROUP_ITEMS_SUCCESS',
  GET_GROUP_SUB_ITEMS: 'GET_GROUP_SUB_ITEMS',
  GET_GROUP_SUB_ITEMS_SUCCESS: 'GET_GROUP_SUB_ITEMS_SUCCESS',
  CREATE_GROUP_OBSERVATION: 'CREATE_GROUP_OBSERVATION',
  // school action names
  GET_SCHOOLS: 'GET_SCHOOLS',
  GET_SCHOOLS_SUCCESS: 'GET_SCHOOLS_SUCCESS',
  CREATE_SCHOOL: 'CREATE_SCHOOL',
  CREATE_SCHOOL_SUCCESS: 'CREATE_SCHOOL_SUCCESS',
  GET_SCHOOL_INFO: 'GET_SCHOOL_INFO',
  GET_SCHOOL_INFO_SUCCESS: 'GET_SCHOOL_INFO_SUCCESS',
  UPDATE_SCHOOL_INFO: 'UPDATE_SCHOOL_INFO',
  CREATE_SCHOOL_ADMIN: 'CREATE_SCHOOL_ADMIN',
  DEACTIVATE_SCHOOL: 'DEACTIVATE_SCHOOL',
  ACTIVATE_SCHOOL: 'ACTIVATE_SCHOOL',
  GET_SCHOOL_YEAR_DATA: 'GET_SCHOOL_YEAR_DATA',
  GET_SCHOOL_YEAR_DATA_SUCCESS: 'GET_SCHOOL_YEAR_DATA_SUCCESS',
  START_SCHOOL_YEAR: 'START_SCHOOL_YEAR',
  EDIT_SCHOOL_YEAR_SETTINGS: 'EDIT_SCHOOL_YEAR_SETTINGS',
  EDIT_ISSUE_DATES: 'EDIT_ISSUE_DATES',
  ACTIVATE_SCHOOL_YEAR: 'ACTIVATE_SCHOOL_YEAR',
  DE_ACTIVATE_FULL_IZEL: 'DE_ACTIVATE_FULL_IZEL',
  DE_ACTIVATE_HALF_IZEL: 'DE_ACTIVATE_HALF_IZEL',
  // students action names
  GET_STUDENTS: 'GET_STUDENTS',
  GET_STUDENTS_SUCCESS: 'GET_STUDENTS_SUCCESS',
  CREATE_STUDENT: 'CREATE_STUDENT',
  UPDATE_STUDENT: 'UPDATE_STUDENT',
  CREATE_STUDENT_SUCCESS: 'CREATE_STUDENT_SUCCESS',
  GET_SUBJECTS_TO_ASSIGN: 'GET_SUBJECTS_TO_ASSIGN',
  GET_SUBJECTS_TO_ASSIGN_SUCCESS: 'GET_SUBJECTS_TO_ASSIGN_SUCCESS',
  ASSIGN_SUBJECTS: 'ASSIGN_SUBJECTS',
  RE_ASSIGN_SUBJECTS: 'RE_ASSIGN_SUBJECTS',
  RE_ASSIGN_ITEMS: 'RE_ASSIGN_ITEMS',
  RE_ASSIGN_SUB_ITEMS: 'RE_ASSIGN_SUB_ITEMS',
  GET_ITEMS_TO_ASSIGN: 'GET_ITEMS_TO_ASSIGN',
  GET_ITEMS_TO_ASSIGN_SUCCESS: 'GET_ITEMS_TO_ASSIGN_SUCCESS',
  ASSIGN_ITEMS: 'ASSIGN_ITEMS',
  GET_SUB_ITEMS_TO_ASSIGN: 'GET_SUB_ITEMS_TO_ASSIGN',
  GET_SUB_ITEMS_TO_ASSIGN_SUCCESS: 'GET_SUB_ITEMS_TO_ASSIGN_SUCCESS',
  ASSIGN_SUB_ITEMS: 'ASSIGN_SUB_ITEMS',
  GET_STUDENT_INFO: 'GET_STUDENT_INFO',
  GET_STUDENT_INFO_SUCCESS: 'GET_STUDENT_INFO_SUCCESS',
  GET_STUDENT_REPORT: 'GET_STUDENT_REPORT',
  GET_STUDENT_REPORT_SUCCESS: 'GET_STUDENT_REPORT_SUCCESS',
  SELF_EVALUATE: 'SELF_EVALUATE',
  SELF_EVALUATE_SUCCESS: 'SELF_EVALUATE_SUCCESS',
  CREATE_SUBJECT_SUMMARY: 'CREATE_SUBJECT_SUMMARY',
  DEACTIVATE_STUDENT: 'DEACTIVATE_STUDENT',
  REACTIVATE_STUDENT: 'REACTIVATE_STUDENT',
  GRANT_ACCESS: 'GRANT_ACCESS',
  REMOVE_ACCESS: 'REMOVE_ACCESS',
  RESEND_LINK: 'RESEND_LINK',
  GET_GRADES_ACTION: 'GET_GRADES_ACTION',
  GET_GRADES_ACTION_SUCCESS: 'GET_GRADES_ACTION_SUCCESS',
  GET_REPORT_LIST: 'GET_REPORT_LIST',
  GET_REPORT_LIST_SUCCESS: 'GET_REPORT_LIST_SUCCESS',
  ACTIVATE_STUDENT_IZEL: 'ACTIVATE_STUDENT_IZEL',
  DE_ACTIVATE_STUDENT_IZEL: 'DE_ACTIVATE_STUDENT_IZEL',
  // report action names
  GET_REPORT: 'GET_REPORT',
  GET_SCHOOL_YEARS: 'GET_SCHOOL_YEARS',
  GET_SCHOOL_YEARS_SUCCESS: 'GET_SCHOOL_YEARS_SUCCESS',
  GET_REPORT_SUCCESS: 'GET_REPORT_SUCCESS',
  EVALUATE_SUB_ITEM: 'EVALUATE_SUB_ITEM',
  EVALUATE_SUB_ITEM_SUCCESS: 'EVALUATE_SUB_ITEM_SUCCESS',
  GET_STUDENT_OBSERVATION: 'GET_STUDENT_OBSERVATION',
  GET_SUBJECT_OBSERVATION: 'GET_SUBJECT_OBSERVATION',
  GET_ITEM_OBSERVATION: 'GET_ITEM_OBSERVATION',
  GET_SUB_ITEM_OBSERVATION: 'GET_SUB_ITEM_OBSERVATION',
  GET_OBSERVATIONS_SUCCESS: 'GET_OBSERVATIONS_SUCCESS',
  GET_SUB_ITEM_EVALUATION: 'GET_SUB_ITEM_EVALUATION',
  GET_SUB_ITEM_EVALUATION_SUCCESS: 'GET_SUB_ITEM_EVALUATION_SUCCESS',
  GET_PRACTICE: 'GET_PRACTICE',
  GET_PRACTICE_SUCCESS: 'GET_PRACTICE_SUCCESS',
  CREATE_PRACTICE: 'CREATE_PRACTICE',
  CREATE_PRACTICE_SUCCESS: 'CREATE_PRACTICE_SUCCESS',
  UPDATE_PRACTICE: 'UPDATE_PRACTICE',
  UPDATE_PRACTICE_SUCCESS: 'UPDATE_PRACTICE_SUCCESS',
  REMOVE_PRACTICE: 'REMOVE_PRACTICE',
  REMOVE_PRACTICE_SUCCESS: 'REMOVE_PRACTICE_SUCCESS',
  GET_PERSONAL_LETTER: 'GET_PERSONAL_LETTER',
  GET_PERSONAL_LETTER_SUCCESS: 'GET_PERSONAL_LETTER_SUCCESS',
  CREATE_PERSONAL_LETTER: 'CREATE_PERSONAL_LETTER',
  GET_ALL_OBSERVATIONS: 'GET_ALL_OBSERVATIONS',
  REMOVE_ALL_OBSERVATIONS: 'REMOVE_ALL_OBSERVATIONS',
  GET_ALL_OBSERVATIONS_SUCCESS: 'GET_ALL_OBSERVATIONS_SUCCESS',
  GET_REPORT_DOCUMENT: 'GET_REPORT_DOCUMENT',
  CREATE_STUDENT_OBSERVATION: 'CREATE_STUDENT_OBSERVATION',
  CREATE_SUBJECT_OBSERVATION: 'CREATE_SUBJECT_OBSERVATION',
  CREATE_ITEM_OBSERVATION: 'CREATE_ITEM_OBSERVATION',
  CREATE_SUB_ITEM_OBSERVATION: 'CREATE_SUB_ITEM_OBSERVATION',
  SAVE_INTERIM_EVALUATION: 'SAVE_INTERIM_EVALUATION',
  GET_OBSERVATION_SUBJECTS: 'GET_OBSERVATION_SUBJECTS',
  GET_OBSERVATION_SUBJECTS_SUCCESS: 'GET_OBSERVATION_SUBJECTS_SUCCESS',
  GET_OBSERVATION_ITEMS: 'GET_OBSERVATION_ITEMS',
  GET_OBSERVATION_ITEMS_SUCCESS: 'GET_OBSERVATION_ITEMS_SUCCESS',
  GET_OBSERVATION_SUB_ITEMS: 'GET_OBSERVATION_SUB_ITEMS',
  GET_OBSERVATION_SUB_ITEMS_SUCCESS: 'GET_OBSERVATION_SUB_ITEMS_SUCCESS',
  // teachers action names
  GET_TEACHERS: 'GET_TEACHERS',
  GET_TEACHERS_SUCCESS: 'GET_TEACHERS_SUCCESS',
  ADD_TEACHER: 'ADD_TEACHER',
  EDIT_TEACHER: 'EDIT_TEACHER',
  ADD_TEACHER_SUCCESS: 'ADD_TEACHER_SUCCESS',
  DEACTIVATE_TEACHER: 'DEACTIVATE_TEACHER',
  RE_DEACTIVATE_TEACHER: 'RE_DEACTIVATE_TEACHER',
};
