import {
  getAllObservations,
  getObservationSubjects,
  getReportSelector,
} from 'store/selectors/report.selectors';
import {
  getAllObservationsAction,
  getObservationSubjectsAction,
  removeAllObservations,
} from 'store/actions/report.actions';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Text } from 'UI/Typography';
import { Col, Row } from 'antd';
import Button from 'UI/Button';
import Modal from 'UI/Modal';
import styles from './styles.module.css';
import Spinner from '../../../../../../UI/Spinner';
import FilterIcon from '../../../../../../UI/Icons/FilterIcon';
import Select from '../../../../../../UI/Select';

type AllObservationsModalTypes = {
  allObservationsModalVisible: boolean;
  toggleAllObservations: (arg1: boolean) => void;
  activeReportId: number;
};

const AllObservations = ({
  allObservationsModalVisible,
  toggleAllObservations,
  activeReportId,
}: AllObservationsModalTypes) => {
  const [page, setPage] = useState(0);
  const [getObservationsLoading, setGetObservationsLoading] = useState(false);
  const [filterEnabled, setFilterEnabled] = useState(false);
  const [selectedSubjects, setSelectedSubjects] = useState<Array<number>>([]);
  const dispatch = useDispatch();
  const observationSubjects = useSelector(getObservationSubjects);
  const observations = useSelector(getAllObservations);
  const report = useSelector(getReportSelector);
  const { studentId } = useParams();

  const allSubjects = useMemo(
    () =>
      observationSubjects.map((value) => {
        return { label: value.name, value: value.id };
      }),
    [observationSubjects],
  );

  const handleChangePage = useCallback(() => {
    setPage((prev) => prev + 1);
  }, []);

  const handleCloseModal = useCallback(() => {
    toggleAllObservations(false);
    dispatch(removeAllObservations);
    setSelectedSubjects([]);
    setPage(0);
    setFilterEnabled(false);
  }, [dispatch, toggleAllObservations]);

  const handleEnableFilters = useCallback(() => {
    setFilterEnabled((prev) => !prev);
  }, []);

  const handleSelectSubjects = useCallback(
    (value: number[]) => {
      dispatch(removeAllObservations);
      setPage(0);
      setSelectedSubjects(value);
      setGetObservationsLoading(true);
    },
    [dispatch],
  );

  useEffect(() => {
    if (allObservationsModalVisible && activeReportId) {
      setGetObservationsLoading(true);
      dispatch(
        getAllObservationsAction({
          studentId: Number(studentId),
          page,
          size: 5,
          onSuccess: () => setGetObservationsLoading(false),
          izelId: activeReportId,
          subjectIds: selectedSubjects,
        }),
      );
    }
  }, [dispatch, studentId, activeReportId, allObservationsModalVisible, selectedSubjects, page]);

  useEffect(() => {
    if (activeReportId) {
      dispatch(
        getObservationSubjectsAction({
          studentId: Number(studentId),
          izelId: activeReportId,
        }),
      );
    }
  }, [activeReportId, dispatch, studentId]);

  return (
    <Modal
      destroyOnClose
      width={800}
      title='Alle Beobachtungen'
      centered
      open={allObservationsModalVisible}
      closable
      okText='Schließen'
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ type: 'default', className: 'primary-40' }}
      onOk={() => handleCloseModal()}
      onCancel={() => handleCloseModal()}
    >
      <div className={styles.observationsWrapper}>
        {getObservationsLoading && !observations ? (
          <Spinner />
        ) : (
          <>
            <div>
              <div className={styles.filterMargin}>
                <Button icon={<FilterIcon />} type='default' onClick={handleEnableFilters}>
                  Filter
                </Button>
              </div>
              {filterEnabled && (
                <div className={styles.filterMargin}>
                  <Select
                    value={selectedSubjects}
                    showSearch
                    mode='multiple'
                    options={allSubjects}
                    style={{ width: '200px' }}
                    maxTagCount='responsive'
                    optionFilterProp='label'
                    onChange={(subjects) => handleSelectSubjects(subjects)}
                    allowClear
                  />
                </div>
              )}
              {observations?.observations?.map((observation) => {
                const breadCrumbs = `${report.surname}, ${report.name} ${
                  observation.subjectName ? '/' + observation.subjectName : ''
                } ${observation.itemName ? '/' + observation.itemName : ''}  ${
                  observation.subItemName ? '/' + observation.subItemName : ''
                }`;
                const split = breadCrumbs.split('/');
                const breadCrumbFirst = [...split];
                breadCrumbFirst.pop();
                return (
                  <div key={observation.id} className={styles.observationInner}>
                    <div className={styles.breadCrumbWrapper}>
                      <Text className='secondary-30' level={1}>
                        {breadCrumbFirst.join(' / ')}
                      </Text>
                      <Text level={1} bold className='secondary-40'>
                        {` ${split.length > 1 ? '/' : ''} ${split[split.length - 1]}`}
                      </Text>
                    </div>
                    <Row gutter={16}>
                      <Col>
                        <Text level={1} bold>
                          {observation.observationDateTime}
                        </Text>
                      </Col>
                      <Col>
                        <Text level={1} bold>
                          {observation.teacherName}
                          {', '}
                          {observation.teacherSurname}
                        </Text>
                      </Col>
                    </Row>
                    <div
                      className='ql-editor'
                      style={{ margin: '8px 0' }}
                      dangerouslySetInnerHTML={{ __html: observation.text }}
                    />
                  </div>
                );
              })}
            </div>
            <div className={styles.showMore}>
              {Number(observations?.observations?.length) !== observations?.count &&
                !!observations?.observations?.length && (
                  <Button
                    type='primary'
                    onClick={handleChangePage}
                    loading={getObservationsLoading}
                  >
                    Mehr anzeigen
                  </Button>
                )}
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default AllObservations;
