import { ActionNames } from 'store/actions/actionNames';

export type getSubjectsParamTypes = {
  page: number;
  size: number;
  name?: string;
  onSuccess?: () => void;
  active: boolean;
};

export type getReorderedSubjectsParamTypes = {
  page: number;
  size: number;
  name?: string;
  onSuccess?: () => void;
};

export type subjectTypes = {
  id: number;
  name: string;
  description?: string;
  optional: boolean;
  usedBy: number;
  items: number;
  schoolYears: number[];
  isAssociation: boolean;
  displayTextAfterContent: boolean;
  active: boolean;
  studentsCount: number;
  groupsCount: number;
  subItemsCount: number;
};

export type subjectResponse = {
  count: number;
  subjects: Array<subjectTypes>;
};

export type createSubjectParamTypes = {
  id?: number;
  name: string;
  optional: boolean;
  description: string;
  page: number;
  displayTextBeforeContent: boolean;
  onSuccess: () => void;
  onFailure: (arg1: string) => void;
  active: boolean;
};

export type reorderSubjectsParamTypes = {
  subjectIds: number[];
  onSuccess: () => void;
  onFailure: () => void;
};

export type deactivateSubjectParamTypes = {
  id: number;
  page: number;
  active: boolean;
  onSuccess: () => void;
};

export const getSubjectsAction = (params: getSubjectsParamTypes) => ({
  type: ActionNames.GET_SUBJECTS,
  params,
});

export const getSubjectsActionSuccess = (subjects: subjectResponse) => ({
  type: ActionNames.GET_SUBJECTS_SUCCESS,
  payload: subjects,
});

export const getReorderedSubjectsList = (params: getReorderedSubjectsParamTypes) => ({
  type: ActionNames.GET_SUBJECTS_TO_REORDER,
  params,
});

export const getReorderedSubjectsListSuccess = (subjects: subjectResponse) => ({
  type: ActionNames.GET_REORDER_LIST_SUCCESS,
  payload: subjects,
});

export const createSubjectAction = (params: createSubjectParamTypes) => ({
  type: ActionNames.CREATE_SUBJECT,
  params,
});

export const editSubjectAction = (params: createSubjectParamTypes) => ({
  type: ActionNames.EDIT_SUBJECT,
  params,
});

export const ReorderSubjectsAction = (params: reorderSubjectsParamTypes) => ({
  type: ActionNames.REORDER_SUBJECTS,
  params,
});

export const deactivateSubjectAction = (params: deactivateSubjectParamTypes) => ({
  type: ActionNames.DEACTIVATE_SUBJECT,
  params,
});

export const reactivateSubjectAction = (params: deactivateSubjectParamTypes) => ({
  type: ActionNames.REACTIVATE_SUBJECT,
  params,
});

export const deleteSubjectAction = (params: deactivateSubjectParamTypes) => ({
  type: ActionNames.DELETE_SUBJECT,
  params,
});

export const clearSubjectListAction = { type: ActionNames.CLEAR_SUBJECT_LIST };
