import { getSubjectsCountSelector, getSubjectsSelector } from 'store/selectors/subjects.selectors';
import {
  deactivateSubjectAction,
  deleteSubjectAction,
  getSubjectsAction,
  reactivateSubjectAction,
} from 'store/actions/subjects.actions';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Text, Title } from 'UI/Typography';
import Spinner from 'UI/Spinner';
import { Col, Dropdown, Row } from 'antd';
import Button from 'UI/Button';
import Input from 'UI/Input';
import Table from 'UI/Table';
import AddSubjectModal from './__partials/AddSubjectModal';
import styles from './styles.module.css';
import Pagination from '../../UI/Pagination';
import { getUserRole } from 'store/selectors/users.selectors';
import EditIcon from '../../UI/Icons/EditIcon';
import ReorderSubjectsModal from 'pages/Subjects/__partials/ReorderSubjectsModal';
import ActionsIcon from '../../UI/Icons/ActionsIcon';
import Modal from '../../UI/Modal';
import Checkbox from '../../UI/Checkbox';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

const { Search } = Input;
const { Confirm } = Modal;

const columns = (
  role: string,
  toggleEditSubject: (arg1: number) => void,
  handleChangeDeactivatingItemId: (arg1: number | null) => void,
  handleReactivateSubject: (id: number) => void,
  handleSelectSubjectToDelete: (id: number) => void,
) => [
  {
    title: 'Fächer',
    dataIndex: 'name',
    key: 'name',
    render: (record: string) => (
      <Text level={1} bold>
        {record}
      </Text>
    ),
  },
  {
    title: 'Typ',
    dataIndex: 'optional',
    key: 'optional',
    render: (type: boolean) => (type ? 'Optional' : 'Bindend'),
  },
  {
    ...(role === 'SCHOOL_ADMIN'
      ? {
          title: 'Autor',
          dataIndex: 'isAssociation',
          key: 'isAssociation',
          render: (record: string) => (record ? 'MLVB' : 'Schule'),
        }
      : {}),
  },
  {
    ...(role === 'SCHOOL_ADMIN'
      ? {
          title: 'Itemanzahl',
          dataIndex: 'items',
          key: 'items',
        }
      : {}),
  },
  {
    title: '',
    dataIndex: 'id',
    key: 'id',
    onCell: () => {
      return {
        onClick: (event: { stopPropagation: () => void }) => {
          event.stopPropagation();
        },
      };
    },
    render: (record: number, i: { isAssociation: boolean; active: boolean }) => {
      return (
        !i.isAssociation && (
          <Dropdown
            overlayClassName={styles.dropdownContent}
            trigger={['click']}
            placement='bottomRight'
            menu={{
              items: [
                {
                  key: '1',
                  label: (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleEditSubject(record);
                      }}
                    >
                      Ändern
                    </span>
                  ),
                },
                ...(role === 'SCHOOL_ADMIN'
                  ? [
                      {
                        key: '2',
                        label: (
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              if (i.active) {
                                handleChangeDeactivatingItemId(record);
                              } else {
                                handleReactivateSubject(record);
                              }
                            }}
                          >
                            {i.active ? 'Deaktivieren' : 'Reaktivieren'}
                          </span>
                        ),
                      },
                    ]
                  : []),
                ...(role === 'SCHOOL_ADMIN' && !i.active
                  ? [
                      {
                        key: '3',
                        label: (
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              handleSelectSubjectToDelete(record);
                            }}
                          >
                            {'Löschen'}
                          </span>
                        ),
                      },
                    ]
                  : []),
              ],
            }}
          >
            <ActionsIcon />
          </Dropdown>
        )
      );
    },
    width: 50,
  },
];

const Subjects = () => {
  const [addItemModalVisible, setAddItemModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [reorderVisible, setReorderVisible] = useState(false);
  const [selectedSubjectId, setSelectedSubject] = useState<number | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [getSubjectsLoading, setGetSubjectsLoading] = useState(false);
  const [deactivationSubjectId, setDeactivatingSubjectId] = useState<number | null>(null);
  const [showDeativatedSubjects, setShowDeactivatedSubjects] = useState(false);
  const [deletingSubjectId, setDeletingSubjectId] = useState<number | null>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const subjects = useSelector(getSubjectsSelector);
  const subjectsCount = useSelector(getSubjectsCountSelector);
  const role = useSelector(getUserRole);

  const itemToDeactivate = useMemo(() => {
    return subjects?.find((subject: { id: number }) => subject.id === deactivationSubjectId);
  }, [deactivationSubjectId, subjects]);

  const selectedSubject = useMemo(() => {
    return subjects?.find((subject: { id: number }) => subject.id === selectedSubjectId);
  }, [selectedSubjectId, subjects]);

  const subjectToDelete = useMemo(() => {
    return subjects?.find((subject: { id: number }) => subject.id === deletingSubjectId);
  }, [deletingSubjectId, subjects]);

  const toggleDeactivatedSubjects = useCallback(() => {
    setCurrentPage(1);
    setShowDeactivatedSubjects((prevState) => !prevState);
  }, []);

  const toggleAddItemModal = useCallback((value: boolean) => {
    setAddItemModalVisible(value);
  }, []);

  const toggleReorderModal = useCallback((value: boolean) => {
    setReorderVisible(value);
  }, []);

  const toggleEditSubject = useCallback((value: number) => {
    setSelectedSubject(value);
    if (value) {
      setAddItemModalVisible(true);
    }
  }, []);

  const handlePageChange = useCallback(
    (page: number) => {
      setCurrentPage(page);
      dispatch(
        getSubjectsAction({
          active: showDeativatedSubjects,
          page: page - 1,
          size: 10,
          ...(searchValue.length > 2 ? { name: `&name=${searchValue}` } : {}),
        }),
      );
    },
    [dispatch, searchValue, showDeativatedSubjects],
  );

  const handleChangeSearchValue = useCallback(
    (value: string) => {
      setSearchValue(value);
      setCurrentPage(1);
      if (value.length > 2) {
        dispatch(
          getSubjectsAction({
            page: 0,
            size: 10,
            name: `&name=${value}`,
            active: showDeativatedSubjects,
          }),
        );
      } else {
        dispatch(getSubjectsAction({ page: 0, size: 10, active: showDeativatedSubjects }));
      }
    },
    [dispatch, showDeativatedSubjects],
  );

  const handleChangeDeactivatingSubjectId = useCallback((value: number | null) => {
    setDeactivatingSubjectId(value);
  }, []);

  const handleDeactivateSubject = useCallback(() => {
    dispatch(
      deactivateSubjectAction({
        id: Number(deactivationSubjectId),
        page: currentPage - 1,
        active: showDeativatedSubjects,
        onSuccess: () => {
          handleChangeDeactivatingSubjectId(null);
        },
      }),
    );
  }, [
    currentPage,
    deactivationSubjectId,
    dispatch,
    handleChangeDeactivatingSubjectId,
    showDeativatedSubjects,
  ]);

  const handleSelectSubjectToDelete = useCallback((id: number | null) => {
    setDeletingSubjectId(id);
  }, []);

  const handleDeleteSubject = useCallback(() => {
    dispatch(
      deleteSubjectAction({
        id: Number(deletingSubjectId),
        page: currentPage - 1,
        active: showDeativatedSubjects,
        onSuccess: () => {
          handleSelectSubjectToDelete(null);
        },
      }),
    );
  }, [
    currentPage,
    deletingSubjectId,
    dispatch,
    handleSelectSubjectToDelete,
    showDeativatedSubjects,
  ]);

  const handleReactivateSubject = useCallback(
    (id: number) => {
      dispatch(
        reactivateSubjectAction({
          id: id,
          page: currentPage - 1,
          active: showDeativatedSubjects,
          onSuccess: () => null,
        }),
      );
    },
    [currentPage, dispatch, showDeativatedSubjects],
  );

  useEffect(() => {
    setGetSubjectsLoading(true);
    dispatch(
      getSubjectsAction({
        active: showDeativatedSubjects,
        page: 0,
        size: 10,
        ...(searchValue.length > 2 ? { name: `&name=${searchValue}` } : {}),
        onSuccess: () => setGetSubjectsLoading(false),
      }),
    );
  }, [dispatch, searchValue, showDeativatedSubjects]);

  return (
    <div className={styles.subjectsWrapper}>
      <div className={styles.titleWrapper}>
        <Title level={1}>Fächer</Title>
      </div>
      <Row className={styles.dashboardWrapper} justify='space-between'>
        <Col>
          <Search
            allowClear
            value={searchValue}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeSearchValue(e.target.value)}
          />
        </Col>
        <Col>
          <Button
            type='default'
            icon={<EditIcon />}
            onClick={() => toggleReorderModal(true)}
            style={{ marginRight: '16px' }}
          >
            Umordnen
          </Button>
          <Button type='primary' icon={<PlusOutlined />} onClick={() => toggleAddItemModal(true)}>
            Schulfach hinzufügen
          </Button>
        </Col>
      </Row>
      {role === 'SCHOOL_ADMIN' && (
        <div className={styles.checkboxWrapper}>
          <Checkbox
            checked={showDeativatedSubjects}
            onChange={(e: CheckboxChangeEvent) => {
              e.preventDefault();
              toggleDeactivatedSubjects();
            }}
          >
            Deaktivierte Fächer anzeigen
          </Checkbox>
        </div>
      )}
      <div className={styles.tableWrapper}>
        <Table
          rowClassName={(record: { active: boolean }) => (!record.active ? 'disabled-row' : '')}
          className={styles.subjectsTable}
          rowKey={(record) => record.id}
          loading={{ indicator: <Spinner />, spinning: getSubjectsLoading }}
          pagination={false}
          dataSource={subjects}
          columns={columns(
            role,
            toggleEditSubject,
            handleChangeDeactivatingSubjectId,
            handleReactivateSubject,
            handleSelectSubjectToDelete,
          )}
          onRow={(record) => {
            return {
              onClick: () => {
                navigate(`/subjects/${record.id}`);
              },
            };
          }}
        />
      </div>
      <div className={styles.paginationWrapper}>
        <Pagination
          pageSize={10}
          current={currentPage}
          onChange={(page: number) => handlePageChange(page)}
          total={subjectsCount}
        />
      </div>
      <ReorderSubjectsModal
        handlePageChange={handlePageChange}
        reorderVisible={reorderVisible}
        toggleReorderModal={toggleReorderModal}
      />
      <AddSubjectModal
        active={showDeativatedSubjects}
        toggleEditSubject={toggleEditSubject}
        selectedSubject={selectedSubject}
        page={currentPage}
        addItemModalVisible={addItemModalVisible}
        toggleAddItemModal={toggleAddItemModal}
      />
      <Confirm
        closable
        okText='Deaktivieren'
        cancelText='Abbrechen'
        centered
        message={`Das Fach ${itemToDeactivate?.name} ist derzeit ${itemToDeactivate?.groupsCount} Gruppen und ${itemToDeactivate?.studentsCount} Schülern und Schülerinnen zugeordnet. Sind sie sicher, dass Sie das Fach ${itemToDeactivate?.name} deaktivieren wollen?`}
        open={!!deactivationSubjectId}
        onOk={(event) => {
          event.stopPropagation();
          handleDeactivateSubject();
        }}
        onCancel={(event) => {
          event.stopPropagation();
          handleChangeDeactivatingSubjectId(null);
        }}
      />
      <Confirm
        closable
        okText='Löschen'
        cancelText='Abbrechen'
        centered
        message={
          <div>
            <Title level={5}>{`Das Fach ${subjectToDelete?.name}`}</Title>
            <ul>
              <li>
                <Title
                  level={5}
                >{`beinhaltet ${subjectToDelete?.items} Items und ${subjectToDelete?.subItemsCount} Sub-Items`}</Title>
              </li>
              <li>
                <Title level={5}>{`ist ${
                  subjectToDelete?.groupsCount || 0
                } Gruppen zugeordnet und`}</Title>
              </li>
              <li>
                <Title
                  level={5}
                >{`ist ${subjectToDelete?.studentsCount} Schülern und Schülerinnen zugeordnet`}</Title>
              </li>
            </ul>
            <Title level={5}>
              {`Durch das Löschen werden alle enthaltenen Items und Subitems ebenfalls gelöscht und die Zuweisungen zu Gruppen und Schülern und Schülerinnen werden entfernt. Wollen Sie das Fach ${subjectToDelete?.name} wirklich löschen?`}
            </Title>
          </div>
        }
        type='node'
        open={!!deletingSubjectId}
        onOk={handleDeleteSubject}
        onCancel={(event) => {
          event.stopPropagation();
          handleSelectSubjectToDelete(null);
        }}
      />
    </div>
  );
};

export default Subjects;
